( ( $ ) => {
  const searchIcon = $( '.header-search' );
  const searchClose = $( '.search-close' );
  const $body = $( 'body' );

  searchIcon.on( 'click', () => {
    $body.addClass( 'search-open' );
  } );
  searchClose.on( 'click', ( e ) => {
    e.stopPropagation();
    $body.removeClass( 'search-open' );
  } );
} )( jQuery );
