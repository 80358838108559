const accordionScroll = ( elements, scrollAlways = false ) => {
  // Early return if no elements are provided
  if ( !elements?.length ) return;

  // Get header height once to avoid repeated DOM queries
  const headerHeight = document.querySelector( 'header' )?.offsetHeight || 0;

  /**
   * Scroll to the expanded accordion card
   * @param {HTMLElement} card - The expanded accordion card
   */
  const scrollToCard = ( card ) => {
    const cardTop = card.getBoundingClientRect().top;
    const shouldScroll = scrollAlways || cardTop < headerHeight;

    if ( shouldScroll ) {
      window.scrollTo( {
        top: cardTop + window.scrollY - ( headerHeight + 70 ),
        behavior: 'smooth'
      } );
    }
  };

  /**
   * Add click event listener to accordion buttons
   * @param {HTMLElement} accordion - The accordion container
   */
  const setupAccordionListeners = ( accordion ) => {
    const accordionButtons = accordion.querySelectorAll( '.accordion__block__btn' );
    
    accordionButtons.forEach( accordionBtn => {
      accordionBtn.addEventListener( 'click', function () {
        const card = this.closest( '.card' );
        
        if ( card ) {
          // Delay to allow for accordion animation
          setTimeout( () => scrollToCard( card ), 700 );
        }
      } );
    } );
  };

  // Setup listeners for each accordion
  elements.forEach( setupAccordionListeners );
};

// Default usage for specific accordion implementation
const initDefaultAccordions = () => {
  const accordionEls = document.querySelectorAll( '.bs-advance-accordion__right-container .card' );
  
  if ( accordionEls?.length ) {
    accordionScroll( accordionEls );
  }
};

// Initialize on DOM load
document.addEventListener( 'DOMContentLoaded', initDefaultAccordions );
