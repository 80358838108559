$( $ => {
  const body = $( 'body' );
  const notificationBar = $( '#notify-bar' );
  if ( !notificationBar.length ) {
    return false;
  }
  
  $( '.notify-bar__close' ).on( 'click', () => {
    notificationBar.slideUp( 400, () => {
      body.removeClass( 'notify-bar-enable' );
    } );
    $.ajax( {
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: {
        action: 'mark_notify_bar_as_read',
      },
    } );
  } );
} );

document.addEventListener( 'DOMContentLoaded', function() {
  const marqueeContainer = document.querySelector( '.notify-bar__title' );
  const marqueeContent = document.querySelector( '.notify-bar__title span' );

  if (
    ( marqueeContent && marqueeContainer ) &&
    ( marqueeContent.clientWidth > marqueeContainer.clientWidth )
  ) {
    const clonedContentOne = marqueeContent.cloneNode( true );
    const clonedContentTwo = marqueeContent.cloneNode( true );
    marqueeContent.classList.add( 'marquee' );
    clonedContentOne.classList.add( 'marquee' );
    clonedContentTwo.classList.add( 'marquee' );
    marqueeContainer.appendChild( clonedContentOne );
    marqueeContainer.appendChild( clonedContentTwo );
    marqueeContainer.classList.add( 'has-marquee' );
  }
} );
