/* stylelint-disable  no-unused-vars*/
class TagsManager {
  constructor( selector ) {
    this.selectors = document.querySelectorAll( selector );
    this.debouncedCalculateTags = this.debounce( this.calculateTags.bind( this ), 100 );
    window.addEventListener( 'resize', this.debouncedCalculateTags );
  }

  calculateTags() {
    this.selectors.forEach( container => {
      let totalTagsWidth = 0;
      const tags = container.querySelectorAll( 'span, a, .news-tags span' ); // Select span and a tags
      const tagsWrapperWidth = container.offsetWidth - 50;
      let hideTagCount = 0;

      // Remove 'more-tags' div if exists
      const existingMoreTags = container.querySelector( '.more-tags' );
      if ( existingMoreTags ) existingMoreTags.remove();

      const moreTagsDiv = document.createElement( 'div' );
      moreTagsDiv.classList.add( 'more-tags' );

      tags.forEach( tag => {
        tag.classList.remove( 'hide' ); // Remove 'hide' class
        totalTagsWidth += tag.offsetWidth; // Calculate width of tags

        // If total width exceeds the container's width, hide the tag
        if ( totalTagsWidth > tagsWrapperWidth ) {
          tag.classList.add( 'hide' );
          hideTagCount++;
        }
      } );

      // If there are hidden tags, show the 'more-tags' div
      if ( hideTagCount > 0 ) {
        moreTagsDiv.textContent = `+${hideTagCount}`;
        container.append( moreTagsDiv );
      }
    } );
  }

  debounce( func, wait ) {
    let timeout;
    return function ( ...args ) {
      clearTimeout( timeout );
      timeout = setTimeout( () => func.apply( this, args ), wait );
    };
  }
}

document.addEventListener( 'DOMContentLoaded', () => {
  new TagsManager(
    '.bs-section--limit-taxonomy .bs-post-taxonomy_news-tag, .bs-div--general-posts .news-tags'
  ).calculateTags();
} );
