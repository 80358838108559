import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function () {
  const duration = 600;
  const footer = document.querySelector( 'footer' );

  const menuSelectors = {
    expanded: 'footer-expanded',
    initialized: 'mobile-menu-initialized'
  };

  function toggleMenu( menuItem ) {
    const isExpanded = menuItem.classList.contains( menuSelectors.expanded );
    collapseAllMenus();
    isExpanded ? collapseMenu( menuItem ) : expandMenu( menuItem );
  }

  function collapseAllMenus() {
    footer.querySelectorAll( `.${menuSelectors.expanded}` ).forEach( collapseMenu );
  }

  function collapseMenu( menuItem ) {
    const subMenu = menuItem.querySelector( '.sub-menu' );
    if ( subMenu ) {
      slideUp( subMenu, duration );
      menuItem.classList.remove( menuSelectors.expanded );
    }
  }

  function expandMenu( menuItem ) {
    const subMenu = menuItem.querySelector( '.sub-menu' );
    if ( subMenu ) {
      slideDown( subMenu, duration );
      menuItem.classList.add( menuSelectors.expanded );
    }
  }

  function handleMenuItemClick( event ) {
    event.preventDefault();
    const menuItem = event.target.parentElement;
    toggleMenu( menuItem );
  }

  function initMobileMenu() {
    footer.querySelectorAll( 'ul > li.menu-item-has-children > a' ).forEach( menuItem => {
      menuItem.addEventListener( 'click', handleMenuItemClick );
    } );
  }

  function handleResize() {
    const isMobile = window.matchMedia( '(max-width: 1200px)' ).matches;
    if ( isMobile && !footer.classList.contains( menuSelectors.initialized ) ) {
      footer.classList.add( menuSelectors.initialized );
      initMobileMenu();
    } else if ( !isMobile ) {
      footer.classList.remove( menuSelectors.initialized );
    }
  }

  handleResize();
  window.addEventListener( 'resize', handleResize );
} );
