import { slideToggle, toggleClass, addClass, removeClass, slideDown } from './common';

const filterAccordion = {
  filterDOM: document.querySelector( '.algolia-search-filter' ),

  init() {
    if ( !this.filterDOM ) return;
    this.accordion();
    this.dropdown();
  },

  accordion() {
    const accordionWrappers = this.filterDOM.querySelectorAll( '.filter-accordion-wrapper' );
    if ( 0 === accordionWrappers.length ) return;

    let animating = false;
    accordionWrappers.forEach( ( acc, i ) => {
      const accBody = acc.querySelector( '.filter-list-wrapper' );

      if ( 0 === i ) {
        slideDown( accBody );
        addClass( acc );
      }

      acc.querySelector( '.filter-list-header' ).addEventListener( 'click', () => {
        if ( animating ) return;
        animating = true;
        slideToggle( accBody );
        toggleClass( acc );
        setTimeout( () => {
          animating = false;
        }, 250 );
      } );
    } );
  },

  dropdown() {
    const ddHeads = this.filterDOM.querySelectorAll( '.filter-dropdown-wrapper' );
    if ( 0 === ddHeads.length ) return;

    ddHeads.forEach( dropdown => {
      dropdown.addEventListener( 'click', ( e ) => {
        e.stopPropagation();
        toggleClass( dropdown );
      } );
    } );

    document.addEventListener( 'click', ( e ) => {
      const dd = e.target.classList.contains( 'filter-dropdown-wrapper' )
        ? e.target
        : e.target.closest( '.filter-dropdown-wrapper' );

      if ( !dd ) {
        ddHeads.forEach( dropdown => {
          removeClass( dropdown );
        } );
      }
    } );
  }
};

filterAccordion.init();
