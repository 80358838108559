// eslint-disable-next-line no-undef
$( ( $ ) => {
  const convertToSlug = ( Text ) => {
    return Text.toLowerCase()
      .replace( / /g, '-' )
      .replace( /[^\w-]+/g, '' );
  };

  const addSinglePostClasss = () => {
    // Resource type add as a class
    $( '.bs-post .bs-post-taxonomy_resource-type span' ).each(
      ( index, element ) => {
        const typeText = $( element ).text();
        $( element )
          .closest( '.bs-post' )
          .addClass( convertToSlug( typeText ) );
      }
    );
  };

  // Add play icon when page load
  $( window ).on( 'load', () => {
    addSinglePostClasss();
  } );
} );
