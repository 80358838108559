import Typed from 'typed.js';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
/* eslint-disable no-unused-vars */
class Typing {
  constructor ( element ) {
    if ( !element ) return;
    this.element = element;
    this.textEl = this.element.querySelector( 'h1' );
    this.wordsEl = this.element.querySelector( '.typing-word-list' );
    this.mainText = this.textEl.innerText;
    this.typingWords = this.wordsEl.innerText;
    this.mainVideo = element.querySelector( '.bs-media-element--hero-banner video' );
    this.isMainTextTyped = false;
    this.noDelay = this.element.classList.contains( 'bs-section--hero-banner-no-delay' );
  }

  appendSpan ( id ) {
    const span = document.createElement( 'span' );
    span.id = id;
    this.textEl.appendChild( span );
  }

  typeFunc ( h1, section ) {
    if( this.isMainTextTyped ) return;
    const mainOptions = {
      strings: [this.mainText],
      typeSpeed: 30,
      backSpeed: 30,
      loop: false,
      showCursor: false,
      onComplete: () => {
        section.classList.add( 'bs-section--heading-typed' );
        const spaceNode = document.createTextNode( ' ' );
        h1.querySelector( '#main-phrase' ).appendChild( spaceNode );
        new Typed( '#sub-phrase', subOptions ).start();
        this.isMainTextTyped = true;
      },
    };
    const subOptions = {
      strings: this.typingWords.split( ',' ),
      typeSpeed: 30,
      backSpeed: 30,
      loop: false,
      showCursor: false,
    };

    new Typed( '#main-phrase', mainOptions ).start();
  }

  scrollFunc () {
    gsap.registerPlugin( ScrollTrigger );
    const glowWrapper = document.querySelector( '.bs-section--bg-glow' );
    const glowBottom = document.querySelector( '.bs-div--bg-glow-bottom' );
    const glowTop = document.querySelector( '.bs-div--bg-glow-top' );
    const contentH = document.querySelector( 'main' ).offsetHeight;
    gsap.to( glowBottom, {
      translateX: 200,
      translateY: 300,
      scale: 0.8,
      width: 2800,
      height: 600,
      opacity: 0,
      scrollTrigger: {
        trigger: glowWrapper,
        start: 'top 0',
        end: `+=${contentH}`,
        scrub: 2,
      },
      duration: 10,
      ease: 'none',
    } );
    gsap.to( glowTop, {
      translateX: 400,
      translateY: 500,
      scale: 0.8,
      width: 2000,
      height: 1300,
      opacity: 0,
      scrollTrigger: {
        trigger: glowWrapper,
        start: 'top 0',
        end: `+=${contentH}`,
        scrub: 2,
      },
      duration: 10,
      ease: 'none',
    } );
  }

  videoPlaying ( video ) {
    if( !this.noDelay ) {
      if( video && window.innerWidth > 991 ) {
        video.addEventListener( 'play', () => {
          this.typeFunc( this.textEl, this.element );
        } );
      } else {
        this.typeFunc( this.textEl, this.element );
      }
    } else {
      this.typeFunc( this.textEl, this.element );
    }
  }

  init () {
    if ( !this.element ) return;
    this.textEl.innerText = '';
    this.appendSpan( 'main-phrase' );
    this.appendSpan( 'sub-phrase' );
    this.textEl.classList.add( 'show' );
    this.videoPlaying( this.mainVideo );
    this.scrollFunc();
  }
}

const typingEl = document.querySelector( '.bs-section--hero-banner' );
const typingObj = new Typing( typingEl );
typingObj.init();
