require( 'slick-carousel-latest' );

const earlyReturn = ( sliderSections ) => {
  if ( sliderSections.length === 0 ) return;
};

const updateSlider = ( slider ) => {  
  const isLargeScreen = $( window ).width() >= 992;
  if ( slider.hasClass( 'slick-initialized' ) ) {
    slider.slick( 'slickSetOption', 'vertical', isLargeScreen );
    slider.slick( 'slickSetOption', 'verticalSwiping', isLargeScreen );
    slider.slick( 'refresh' );
  }
};

( ( $ ) => {
  const sliderSections = $( '.bs-section--history-slider' );
  earlyReturn( sliderSections );

  sliderSections.each( ( i, section ) => {
    const sliderSection = $( section );
    const sliderTabs = sliderSection.find( '.bs-tab-slider .bs-slider-tabs' );
    const sliderContents = sliderSection.find( '.bs-tab-slider .bs-slider-content' );

    // Helper: Add class with conditions
    const updateSlideClasses = ( slides, currentSlide ) => {
      const totalSlides = slides.length;
      const topBottomClasses = 'top-slide bottom-slide';
      const highlightIndexes = Array.from( { length: 7 }, ( _, i ) => 
        ( currentSlide + i - 3 + totalSlides ) % totalSlides
      );

      slides.removeClass(
        'prev prev-prev prev-prev-prev next next-next next-next-next current-slide ' +
        topBottomClasses
      );

      // Add highlight classes
      slides.eq( currentSlide ).addClass( 'current-slide' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[0] ).addClass( 'prev-prev-prev' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[1] ).addClass( 'prev-prev' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[2] ).addClass( 'prev' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[4] ).addClass( 'next' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[5] ).addClass( 'next-next' ).removeClass( topBottomClasses );
      slides.eq( highlightIndexes[6] ).addClass( 'next-next-next' ).removeClass( topBottomClasses );

      // Add top and bottom classes
      slides.each( ( i, slide ) => {
        const $slide = $( slide );
        if ( !highlightIndexes.includes( i ) ) {
          $slide.addClass( i < currentSlide ? 'top-slide' : 'bottom-slide' );
        }
      } );
    };

    // Helper: Update custom arrows
    const updateArrowStates = ( currentSlide, totalSlides ) => {
      sliderSection.find( '.arr-prev' ).toggleClass( 'disabled', currentSlide === 0 );
      sliderSection.find( '.arr-next' ).toggleClass( 'disabled', currentSlide === totalSlides - 1 );
    };

    // Add custom arrows
    const createCustomArrows = () => {
      sliderSection.find( '.bs-tab-slider' ).append( `
        <div class="custom-arrows">
          <span class="arr-prev"></span>
          <span class="arr-next"></span>
        </div>
      ` );

      sliderSection.find( '.arr-next' ).on( 'click', () => {
        sliderContents.slick( 'slickNext' );
      } );

      sliderSection.find( '.arr-prev' ).on( 'click', () => {
        sliderContents.slick( 'slickPrev' );
      } );
    };

    // Initialize slider after change
    const initializeSliderTabs = () => {
      const instance = sliderTabs.slick( 'getSlick' );
      instance.$slider.on( 'afterChange', ( e, slick, currentSlide ) => {
        const slides = slick.$slides;
        updateSlideClasses( slides, currentSlide );
        updateArrowStates( currentSlide, slides.length );
      } );
    };

    // Update slick options
    const updateSlickOptions = () => {
      updateSlider( sliderTabs );
      updateSlider( sliderContents );
    };

    // Wait for sliders to initialize
    const waitForInitialization = setInterval( () => {
      if ( sliderTabs.hasClass( 'slick-initialized' ) && sliderContents.hasClass( 'slick-initialized' ) ) {
        createCustomArrows();
        initializeSliderTabs();
        updateSlickOptions();
        clearInterval( waitForInitialization );
      }
    }, 10 );

    // Handle window resize
    $( window ).on( 'resize', updateSlickOptions );
  } );
} )( jQuery );
