// Function to wrap "TM" with <sup>
function wrapTMWithSup() {
  // Get all text nodes in the document
  const walker = document.createTreeWalker( document.body, NodeFilter.SHOW_TEXT );
  let node;

  // eslint-disable-next-line no-cond-assign
  while ( node = walker.nextNode() ) {
    // Check if the text contains "TM"
    if ( node.nodeValue.includes( '™' ) ) {
      // Replace "TM" with a <sup> wrapped version
      const parent = node.parentNode;
      const fragments = node.nodeValue.split( '™' );
      const fragment = document.createDocumentFragment();

      fragments.forEach( ( text, index ) => {
        fragment.appendChild( document.createTextNode( text ) );
        if ( index < fragments.length - 1 ) {
          const sup = document.createElement( 'sup' );
          sup.textContent = '™';
          fragment.appendChild( sup );
        }
      } );

      parent.replaceChild( fragment, node );
    }
  }
}

// Run the function
wrapTMWithSup();
