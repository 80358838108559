document.addEventListener( 'click', ( e ) => {
  if ( e.target.classList.contains( 'ais-Pagination-link' ) ) {
    const adminBar = document.getElementById( 'wpadminbar' );
    const header = document.querySelector( 'header' );
    const adminBarHeight = adminBar ? adminBar.offsetHeight : 0;
    const headerHeight = header ? header.offsetHeight : 0;
    const totalHeaderHeight = adminBarHeight + headerHeight;

    let currentElement = e.target;
    while ( currentElement && currentElement.tagName !== 'SECTION' ) {
      currentElement = currentElement.parentElement;
    }

    if ( currentElement ) {
      const targetSectionTop = currentElement.offsetTop;
      window.scrollTo( {
        top: targetSectionTop - totalHeaderHeight,
        behavior: 'smooth',
      } );
    }
  }
} );
