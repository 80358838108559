const videoTabSlider = {
  sliders: document.querySelectorAll( '.bs-section--video-tab-slider' ),

  init() {
    if ( this.sliders.length === 0 ) return;

    this.sliders.forEach( slider => {
      slider.addEventListener( 'click', ( e ) => {
        if (
          e.target.classList.contains( 'bs-slider-tabs' ) ||
          e.target.closest( '.bs-slider-tabs' ) ||
          e.target.classList.contains( 'slick-arrow' )
        ) {
          slider.querySelectorAll( '.slick-slide-animator' ).forEach( animator => {
            animator.style.animationDuration = '0s';
          } );
        }
      } );
    } );
  }
};
videoTabSlider.init();
