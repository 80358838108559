class ScrollingCards {
  constructor( selector ) {
    this.section = document.querySelector( selector );
    this.cards = this.section.querySelectorAll( '.slick-slide-wrapper' );
    this.firstCard = this.cards[0];
    this.stickySpace = 160;
    this.navGap = 10;
    this.cardGap = 20;
    this.preNav = null;
    this.intiFunction = false;
    if ( this.section ) {
      window.addEventListener( 'scroll', () => this.updateProgressBars() );
      window.addEventListener( 'resize', () => this.setUniformCardHeight() );
    }
  }
  observeSection() {
    const observer = new IntersectionObserver( ( entries, observer ) => {
      entries.forEach( entry => {
        if ( entry.isIntersecting && this.intiFunction === false ) {
          this.init();
          observer.unobserve( entry.target );
          this.intiFunction = true;
        }
      } );
    }, { threshold: 0.1 } );
    observer.observe( this.section );
  }
  init() {
    this.calculateMaxCardHeight();
    this.setUniformCardHeight();
    this.createTabNavigation();
    this.calculateCardPositions();
    this.setupMutationObserver();
    this.setupTabNavigation();
    this.updateProgressBars();
  }
  createTabNavigation() {
    if ( !this.firstCard ) return;
    const tabsWrapperDiv = document.createElement( 'div' );
    tabsWrapperDiv.classList.add( 'progress-tabs-wrapper' );
    tabsWrapperDiv.append( ...this.cloneTabs() );
    this.firstCard.prepend( tabsWrapperDiv );
    this.updateCardStickyPositions();
  }
  cloneTabs() {
    const tabs = this.section.querySelectorAll( '.bs-div--logo-wrapper-block' );
    return Array.from( tabs ).map( tab => {
      const clonedTab = tab.cloneNode( true );
      const progressBar = this.createProgressBar();
      clonedTab.appendChild( progressBar );
      return clonedTab;
    } );
  }
  createProgressBar() {
    const progressDiv = document.createElement( 'div' );
    const progressBarInner = document.createElement( 'div' );
    progressDiv.classList.add( 'scroll-progress-bar' );
    progressBarInner.classList.add( 'scroll-progress-bar__inner' );
    progressDiv.appendChild( progressBarInner );
    return progressDiv;
  }
  activeNav( preNav, currentCardIndex ) {
    const tabs = this.section.querySelectorAll( '.bs-div--logo-wrapper-block' );
    tabs.forEach( ( tab, i ) => {
      if ( i < currentCardIndex ) {
        tab.classList.add( 'filled' );
      } else {
        tab.classList.remove( 'filled' );
      }
    } );
    tabs[preNav]?.classList.remove( 'active' );
    tabs[currentCardIndex]?.classList.add( 'active' );
  }
  blurCard( drowProgresBar, index ) {
    const cards = this.section.querySelectorAll( '.slick-slide-wrapper' );
    if ( drowProgresBar > 50 ) {
      cards[index-1]?.classList.add( 'card-blur' );
    } else {
      cards[index-1]?.classList.remove( 'card-blur' );
    }
  }
  updateProgressBars() {
    const deduction = window.innerHeight - (
      this.cards[this.cards.length - 1].getBoundingClientRect().height + this.stickyValue - this.cardGap
    );
    const windowHeight = window.innerHeight - deduction;
    let currentCardIndex = null;
    
    
    this.cards.forEach( ( card, index ) => {
      const actualViewPort = ( index == 0 )? windowHeight - this.stickySpace : windowHeight - this.stickyValue ;
      const cardTop = card.getBoundingClientRect().top;
      const cardVisiblePart = windowHeight - cardTop;
      const drowProgresBar = cardVisiblePart / actualViewPort * 100;
      if ( cardTop <= windowHeight ) {
        this.updateProgressBar( drowProgresBar, index );
        this.updateProgressBar( 100, index-1 );
        this.blurCard( drowProgresBar, index );
        currentCardIndex = index;
      } else {
        this.updateProgressBar( 0, index );
      }
    } );
    this.activeNav( this.preNav, currentCardIndex );
    this.preNav = currentCardIndex;
    this.updateCardVisibility( currentCardIndex );
  }
  updateCardVisibility( currentCardIndex ) {
    if ( currentCardIndex !== null ) {
      if ( currentCardIndex > this.previousCardIndex ) {
        if ( this.previousCardIndex !== null ) {
          this.cards[this.previousCardIndex].classList.add( 'previous-card' );
        }
      } else if ( currentCardIndex <= this.previousCardIndex ) {
        this.cards[this.previousCardIndex].classList.remove( 'previous-card' );
      }
      this.previousCardIndex = currentCardIndex;
    }
  }
  updateProgressBar( value, index ) {
    const progressBarLine = this.section.querySelectorAll( 
      '.progress-tabs-wrapper .bs-div--logo-wrapper-block' 
    )[index];
    const progressBarInner = progressBarLine?.querySelector( '.scroll-progress-bar__inner' );
    if ( progressBarInner ) {
      const minValue = 0;
      const maxValue = 100;
      const constrainedValue = Math.max( minValue, Math.min( maxValue, value ) );
      progressBarInner.style.width = `${constrainedValue}%`;
    }
  }
  updateCardStickyPositions() {
    const progressBar = this.section.querySelector( '.progress-tabs-wrapper' );
    if ( !progressBar ) return;
    const progressBarHeight = progressBar.getBoundingClientRect().height;
    this.cards[0].style.top = `${this.stickySpace}px`;
    this.cards.forEach( ( card, index ) => {
      if ( index !== 0 ) {
        this.stickyValue = this.stickySpace + progressBarHeight + this.navGap;
        card.style.top = `${this.stickyValue}px`;
      }
    } );
  }
  calculateCardPositions() {
    let targetCardPos = [];
    const windowScrollPos = window.scrollY;
    this.cards.forEach( ( card, index ) => {
      const gap = ( index === 1 ) ? this.navGap : 0 ;
      targetCardPos.push( card.getBoundingClientRect().top + windowScrollPos + gap );
    } );
    return targetCardPos;
  }
  setupTabNavigation() {
    const tabs = this.section.querySelectorAll( 
      '.progress-tabs-wrapper .bs-div--logo-wrapper-block' 
    );
    const headerHeight = document.querySelector( 'header' ).getBoundingClientRect().height;
    const cardPos = this.calculateCardPositions();
    tabs.forEach( ( tab, index ) => {
      tab.addEventListener( 'click', () => {
        if ( index == 0 ) {
          const scrollPosCalc = cardPos[index] - this.stickySpace;
          this.windowScroller( scrollPosCalc );
        } else {
          const scrollPosCalc = cardPos[index] - this.stickySpace - headerHeight + ( 
            this.cardGap * index+1 
          ) + this.navGap; 
          this.windowScroller( scrollPosCalc );
        }
      } );
    } );
  }
  calculateMaxCardHeight() {
    let maxHeight = 0;
    this.cards.forEach( ( card ) => {
      const cardHeight = card.querySelector( '.bs-div--main-wrapper' ).getBoundingClientRect().height;
      maxHeight = Math.max( maxHeight, cardHeight );
    } );
    return maxHeight;
  }
  setUniformCardHeight() {
    this.cards.forEach( ( card ) => {
      const cardHeight = ( window.innerWidth > 991 ) ? `${this.calculateMaxCardHeight()}px` : '100%' ;
      card.querySelector( '.bs-div--main-wrapper' ).style.height = cardHeight;
    } );
  }
  windowScroller( scrollPosition ) {
    window.scrollTo( {
      top: scrollPosition,
      behavior: 'smooth'
    } );
  } 
  setupMutationObserver() {
    const observer = new MutationObserver( ( mutations ) => {
      for ( let mutation of mutations ) {
        if ( mutation.type === 'childList' ) {
          this.updateCardStickyPositions();
        }
      }
    } );
    observer.observe( this.section, { childList: true, subtree: true } );
  }
}

const scrollingCardsEl = document.querySelector( '.bs-section--scrolling-cards' );
if( scrollingCardsEl !== null ) {
  const scrollingCards = new ScrollingCards( '.bs-section--scrolling-cards' );
  document.addEventListener( 'DOMContentLoaded', () => {
    scrollingCards.observeSection();
  } );
}
