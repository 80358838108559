$( document ).ready( function () {
  const collapse = $( '.bs-advance-accordion--additional-tabs .bs-advance-accordion__right-container .card .collapse' );
  if ( window.matchMedia( '(min-width: 1200px)' ).matches ) {
    collapse.remove();
  }

  $( '.bs-advance-accordion--additional-tabs .bs-advance-accordion__right-container .card:first' )
    .addClass( 'active' );


  $( '.bs-advance-accordion--additional-tabs .bs-advance-accordion__left-container ' +
    '.bs-advance-accordion__left-content-panel:nth-child(2)' )
    .addClass( 'show' );
} );
