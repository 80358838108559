document.addEventListener( 'DOMContentLoaded', function () {
  const genericLinks = document.querySelectorAll( '.bs-div--generic-link' );
  if ( genericLinks.length === 0 ) {
    return;
  }
  genericLinks.forEach( linkDiv => {
    const buttons = linkDiv.querySelectorAll( '.bs-pro-button' );

    buttons.forEach( button => {
      const prefix = button.querySelector( '.bs-pro-button__prefix' );
      const container = button.querySelector( '.bs-pro-button__container' );
      if ( prefix && container ) {
        container.prepend( prefix );
      }
    } );
  } );
} );
