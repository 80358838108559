import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );
class TOC {
  constructor ( el ) {
    this.toc = el;
    this.tocItems = this.toc.querySelectorAll( '[id*="toc-item-"]' );
    this.tocHeadings = document.querySelectorAll( '[id*="toc-heading-"]' );
    this.hH = document.querySelector( 'header' )?.offsetHeight || 0;
    this.tocItemIdPrefix = 'toc-item-';
    this.tocHeadingIdPrefix = 'toc-heading-';
    this.clicked = false;
  }

  setTocItemActive( itemId ) {
    if( !this.clicked ) {
      this.toc.querySelectorAll( '[id*="toc-item-"]' ).forEach( ( item ) => {
        item.classList.remove( 'active' );
      } );
      this.toc.querySelector( `#${this.tocItemIdPrefix}${itemId}` ).classList.add( 'active' );
    }
  }

  tocItemClick ( itemId ) {
    this.setTocItemActive( itemId );
    this.clicked = true;
  }

  tocItemClickEvent () {
    this.tocItems.forEach( ( tocItem ) => {
      tocItem.addEventListener( 'click', () => {
        const itemId = tocItem.id.split( this.tocItemIdPrefix )[1];
        const heading = document.querySelector( `#${this.tocHeadingIdPrefix}${itemId}` );
        if( heading ) {
          this.tocItemClick( itemId );
          this.scrollToHeading( heading );
        }
      } );
    } );
  }

  scrollToHeading ( heading ) {
    const headingTop = heading?.getBoundingClientRect().top;
    const hH = document.querySelector( 'header' )?.offsetHeight || 0;
    if( heading ) {
      window.scrollTo( {
        top: ( headingTop + window.scrollY ) - ( hH + 10 ) ,
        behavior: 'smooth'
      } );
    }
  }

  checkSticky () {
    if ( !this.toc ) {
      return;
    }
    const vh = window.innerHeight;
    const tocH = this.toc.offsetHeight;
    if ( tocH > ( vh - this.hH - 10 ) ) {
      this.toc.classList.add( 'remove-sticky' );
    } else {
      this.toc.classList.remove( 'remove-sticky' );
    }
  }

  scrollFunc () {
    const headings = gsap.utils.toArray( this.tocHeadings );
    headings.forEach( ( heading, id ) => {
      const headingH = heading.offsetHeight;
      const headingTop = heading?.getBoundingClientRect().top;
      const nextHeading = this.tocHeadings[id + 1];
      const nextHeadingTop = nextHeading?.getBoundingClientRect().top;
      const endPosition = nextHeading ? nextHeadingTop - headingTop : headingH;
      const startPosition = this.hH + 30;
      const headingId = heading.id.split( this.tocHeadingIdPrefix )[1];
      const tocItem = this.toc.querySelector( `#${this.tocItemIdPrefix}${headingId}` );
      gsap.to( heading, {
        scrollTrigger: {
          trigger: heading,
          start: `top ${startPosition}`,
          end: `+=${endPosition}`,
          scrub: 0.8,
          invalidateOnResize: true,
          onEnter: () => {
            if( tocItem ) {
              this.setTocItemActive( headingId );
            }           
          },
          onEnterBack: () => {
            if( tocItem ) {
              this.setTocItemActive( headingId );
            } 
          },
        }
      } );
    } );
  }

  isScrolling () {
    let isScrolling;
    window.addEventListener( 'scroll', () => {
      window.clearTimeout( isScrolling );
      isScrolling = setTimeout( () => {
        this.clicked = false;
      }, 150 );
    } );
  }

  init () {
    if ( !this.toc ) {
      return;
    }
    const firstTocItemId = this.tocItems[0].id.split( this.tocItemIdPrefix )[1];
    this.setTocItemActive( firstTocItemId );
    this.tocItemClickEvent();
    this.checkSticky();
    this.scrollFunc();
    this.isScrolling();
  }
}

const tocEls = document.querySelectorAll( '.bs-column--toc' );
const tocElObjs = [];
tocEls.forEach( ( tocEl ) => {
  const tocObj = new TOC( tocEl );
  tocElObjs.push( tocObj );
} );
const loadHandler = () => {
  tocElObjs.forEach( ( tocElObj ) => {
    tocElObj.init();
  } );
};
const resizeHandler = () => {
  tocElObjs.forEach( ( tocElObj ) => {
    tocElObj.checkSticky();
  } );
};
window.addEventListener( 'load', loadHandler );
window.addEventListener( 'orientationchange', resizeHandler );
