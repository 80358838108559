const multiElements = document.querySelectorAll( 
  'header .navbar-collapse #mega-menu-main-menu .multilevel-inner > a.mega-menu-link' 
);
const multiElementsWrapper = document.querySelectorAll( 
  'header .navbar-collapse #mega-menu-main-menu .multilevel-inner' 
);
const firstMultiLevel = document.querySelector( 
  'header .navbar-collapse #mega-menu-main-menu .multilevel' 
);
let previouslyHoveredMenu = null;

const activeFirstMenu = () => {
  if ( multiElementsWrapper.length > 0 ) {
    const firstInnerMenu = multiElementsWrapper[0].querySelector( 'ul.mega-sub-menu' );
    if ( firstInnerMenu ) {
      firstInnerMenu.classList.add( 'hovered' );
      previouslyHoveredMenu = firstInnerMenu;
    }
  }
};
activeFirstMenu();
multiElements.forEach( function( item ) {
  item.addEventListener( 'mouseenter', function() {
    if ( previouslyHoveredMenu ) {
      previouslyHoveredMenu.classList.remove( 'hovered' );
    }

    const innerMenu = item.nextElementSibling;
    if ( innerMenu && innerMenu.classList.contains( 'mega-sub-menu' ) ) {
      innerMenu.classList.add( 'hovered' );
      previouslyHoveredMenu = innerMenu;
    }
  } );
} );
if ( firstMultiLevel ) {
  firstMultiLevel.addEventListener( 'mouseenter', function() {
    if ( previouslyHoveredMenu ) {
      previouslyHoveredMenu.classList.remove( 'hovered' );
    }
    activeFirstMenu();
  } );
}
