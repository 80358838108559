import { slideToggle, toggleClass, addClass, slideDown } from './common';

const filterAccordion = {
  filterDOM: document.querySelector( '.bs-column--search-filter-facetwp' ),

  init() {
    if ( !this.filterDOM ) return;

    this.accordion();
  },

  accordion() {
    const accordionWrappers = this.filterDOM.querySelectorAll( '#all-filter-wrapper > .bs-div__inner > .bs-div' );
    if ( 0 === accordionWrappers.length ) return;

    let animating = false;
    accordionWrappers.forEach( ( acc, i ) => {
      const accBody = acc.querySelector( ':scope .bs-div__inner > .facetwp-facet' );

      if ( 0 === i ) {
        slideDown( accBody );
        addClass( acc );
      }

      acc.querySelector( ':scope .bs-div__inner > p' ).addEventListener( 'click', () => {
        if ( animating ) return;
        animating = true;
        slideToggle( accBody );
        toggleClass( acc );
        setTimeout( () => {
          animating = false;
        }, 250 );
      } );
    } );
  }
};

filterAccordion.init();
