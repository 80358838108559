const EventFormWrap = {
  column: document.querySelector( '.bs-column--mig-events-form' ),

  init() {
    if ( !this.column ) return;

    if ( screen.width > 991 ) {
      const contentCol = this.column.closest( 'section' )?.querySelector( '.bs-column--events-inner-content' );
      const observer = new MutationObserver( () => {
        contentCol.style.minHeight = `${this.column.offsetHeight}px`;
        observer.disconnect();
      } );
      observer.observe( this.column, { childList: true, subtree: true } );
    }
  }
};

document.addEventListener( 'DOMContentLoaded', () => {
  EventFormWrap.init();
} );
