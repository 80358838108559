const { DateTime } = require( 'luxon' );
( $ => {
  // Convert datetime string according to timezone.
  function convertTime( originalDateTimeString, sourceTimeZone, targetTimeZone ) {
    const zoneOptions = { zone: sourceTimeZone };
    const originalDateTime = DateTime.fromFormat( originalDateTimeString, 'yyyy-MM-dd H:m:s', zoneOptions );
    let target;
    if ( sourceTimeZone === targetTimeZone ) {
      target = new Date( originalDateTime.toFormat( 'yyyy-MM-dd HH:mm:ss' ) ).getTime();
    } else {
      // Set the target timezone.
      const targetDateTime = originalDateTime.setZone( targetTimeZone );
      target = new Date( targetDateTime.toFormat( 'yyyy-MM-dd HH:mm:ss' ) ).getTime();
    }
    return target;
  }
  // Pad 0 to less than 10 number.
  function pad( number ) {
    return String( number ).padStart( 2, '0' );
  }
  if ( $( '.vc-events-countdown .countdown' ).length ) {
    // Get the original date and time string.
    const originalDateTimeString = $( '.vc-events-countdown .countdown .time' ).text();
    const eventEndDateString = $( '.vc-events-countdown .countdown .time-end' ).text();
    const sourceTimeZone = $( '.vc-events-countdown .countdown .timezone' ).text();
    const targetTimeZone = $( '.vc-events-countdown .countdown .timezone-default' ).text();

    const eventEndTime = convertTime( eventEndDateString, sourceTimeZone, targetTimeZone );
    const currentTime = new Date(
      new Date().toLocaleString( 'en-US', { timeZone: targetTimeZone } ) ).getTime();

    if ( currentTime > eventEndTime ) {
      $( '.vc-events-countdown' ).remove(); // Ensure it’s fully removed
      return; // Stop execution if event has ended
    }

    const target = convertTime( originalDateTimeString, sourceTimeZone, targetTimeZone );
    // Update the countdown every 1 second.
    const count = setInterval( function () {
      // Get current time.
      const now = new Date( new Date().toLocaleString( 'en-US', { timeZone: targetTimeZone } ) ).getTime();
      // Find the distance between now and the countdown date.
      const distance = target - now;
      // Hide finished count down.
      if ( distance < 0 ) {
        clearInterval( count );
        $( '.vc-events-countdown' ).remove(); // Remove countdown if time expired
      } else {
        $( '.vc-events-countdown .countdown .day' ).text( pad(
          Math.floor( distance / ( 1000 * 60 * 60 * 24 ) ) ) );
        $( '.vc-events-countdown .countdown .hour' ).text( pad(
          Math.floor( ( distance % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) ) ) );
        $( '.vc-events-countdown .countdown .minute' ).text( pad(
          Math.floor( ( distance % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) ) ) );
        $( '.vc-events-countdown .countdown .second' ).text( pad(
          Math.floor( ( distance % ( 1000 * 60 ) ) / 1000 ) ) );

        $( '.vc-events-countdown' ).css( 'display', 'block' ); // Show countdown only if valid
      }
    }, 1000 );
  }
} )( jQuery );