( ( $ ) => {
  // Get all logos in the element
  const getLogosItem = ( slideParent ) => {
    return slideParent.find( 'ul li' );
  };

  // Generate animation speed
  const generateAnimationSpeed = ( elem ) => {
    const elementDataSpeed = $( elem ).attr( 'data-speed' );
    return elementDataSpeed / 1000;
  };

  const $commonLogoSliders = $(
    '.bs-section--moving-logo-slider, .bs-section--demo-banner'
  );

  if ( $commonLogoSliders.length > 0 ) {
    const logoPlayHandler = ( slider ) => {
      const sliderParent = slider.parent[0],
        sliderLogos = slider.logos;

      const desktopLogoCount = sliderLogos.length,
        animateSpeed = generateAnimationSpeed( sliderParent );

      // Set slider animate class if logo count is more than 2
      const sliderStartClass = desktopLogoCount > 2 ? 'start-marquee' : 'stop-playing';

      $( sliderParent )
        .find( 'ul' )
        .addClass( sliderStartClass )
        .html( '' )
        .append( sliderLogos.clone() )
        .append( sliderLogos.clone() )
        .append( sliderLogos.clone() )
        .append( sliderLogos.clone() )
        .append( sliderLogos.clone() )
        .append( sliderLogos.clone() )
        .css( 'animation-duration', `${animateSpeed}s` );
    };

    // Loop through all logo slider sections
    $commonLogoSliders.each( ( ind, ele ) => {
      const $logoSliderSingleSection = $( ele );

      if ( $logoSliderSingleSection.length !== 0 ) {
        const $logoSliders = $logoSliderSingleSection.find( '.clients-slider-wrapper' );

        // Loop through each wrapper in the section
        $logoSliders.each( ( _, sliderElement ) => {
          const $slider = $( sliderElement );
          const slideLogos = getLogosItem( $slider );

          // Create slider object for modification
          const sliderData = {
            parent: $slider,
            logos: slideLogos,
          };

          logoPlayHandler( sliderData );
        } );
      }
    } );

    // Toggle 'stop-hover' class on mouse events
    $(
      '.bs-section--moving-logo-slider ul.clients-list li a, ' +
      '.bs-section--demo-banner ul.clients-list li a'
    ).on( 'mouseenter mouseleave', () => {
      $( '.clients-slider-wrapper' ).toggleClass( 'stop-hover' );
    } );
  }
} )( jQuery );
