/* eslint-env jquery */
( ( $ ) => {
  const marqueeSections = $( '.bs-section--dual-stat' );
  $( () => {
    marqueeSections.each( ( ind, sec ) => {
      const marqueeRows = $( sec ).find( 
        '[class*="bs-div--animation-duration-"], .moving-card-slider-wrapper' 
      );
      let minLogoCount = 1000;
      marqueeRows.each( ( ind, ele ) => {
        const logoCount = $( ele ).find(
          '.bs-div--animate-card'
        ).length;
        if ( minLogoCount > logoCount ) {
          minLogoCount = logoCount;
        }
      } );
      marqueeRows.each( ( ind, ele ) => {
        const marqueeRow = $( ele ),
          marqueeDefaultSpeedBE = parseFloat( marqueeRow.attr( 'data-speed' ) ) / 1000,
          marqueeDefaultSpeed = marqueeDefaultSpeedBE ? marqueeDefaultSpeedBE : marqueeRow
            .find( '>.bs-div__inner' )
            .css( 'animation-duration' ),
          marqueeItems = marqueeRow.find( '.bs-div--animate-card' ),
          marqueeItemsCount = marqueeItems.length,
          marqueeSpeed =
            ( parseFloat( marqueeDefaultSpeed ) / minLogoCount ) *
            marqueeItemsCount;
        marqueeRow
          .find( '>.bs-div__inner' )
          .append( marqueeItems.clone() )
          .append( marqueeItems.clone() )
          .append( marqueeItems.clone() )
          .append( marqueeItems.clone() )
          .append( marqueeItems.clone() )
          .addClass( 'start-marquee' )
          .css( 'animation-duration', `${marqueeSpeed}s` );
      } );
    } );
  } );
} )( jQuery );
