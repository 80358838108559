import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );
class ScrollSlider {
  constructor( element ) {
    this.scroll = element;
    this.scrollSlides = this.scroll.querySelectorAll( '.bs-div--on-scroll-slide' );
    this.scrollSlidesWrapper = this.scroll.querySelector( '.bs-div--on-scroll-slides-wrapper' );
    this.wW = window.innerWidth;
    this.hH = document.querySelector( 'header' )?.offsetHeight || 0;
    this.isMobile = this.wW < 768;
  }

  createDOM() {
    if( this.isMobile ) {
      return;
    }
    const mediaHolder = document.createElement( 'div' );
    mediaHolder.classList.add( 'bs-media-wrapper' );
    this.scrollSlidesWrapper.appendChild( mediaHolder );
    const mediaWrapper = this.scroll.querySelector( '.bs-media-wrapper' );
    this.scrollSlides?.forEach( ( scrollSlide, i ) => {
      const mediaEl = scrollSlide.querySelector( '.bs-div--on-scroll-slide-media' );
      scrollSlide.setAttribute( 'data-slide', `slide-${i + 1}` );
      mediaEl.setAttribute( 'data-slide', `slide-${i + 1}` );
      if( i == 0 ) {
        mediaEl.classList.add( 'active' );
      }
      mediaWrapper?.appendChild( mediaEl );
    } );
    this.scrollSlidesWrapper.classList.add( 'bs-scroll-init' );
  }

  onEnterFunc( id ) {
    const medias = this.scroll.querySelectorAll( '.bs-div--on-scroll-slide-media' );
    medias[id].querySelector( 'video' )?.play();
    medias.forEach( ( media, index ) => {
      media.classList.remove( 'active' );
      if ( id === index ) {
        media.classList.add( 'active' );
      }
    } );
  }

  onLeaveFunc( id ) {
    const medias = this.scroll.querySelectorAll( '.bs-div--on-scroll-slide-media' );
    medias[id].querySelector( 'video' )?.pause();
  }

  scrollFunc () {
    const slides = gsap.utils.toArray( this.scrollSlides );
    slides.forEach( ( slide, id ) => {
      const slideH = slide.offsetHeight;
      const startPosition = this.isMobile ? this.hH + 350 : this.hH + 250;
      const isLastSlide = this.scrollSlides.length == id + 1;
      const endPosition = isLastSlide && this.isMobile ? slideH + 250 : slideH;
      gsap.to( slide, {
        scrollTrigger: {
          trigger: slide,
          start: `top ${startPosition}`,
          end: `+=${endPosition}`,
          scrub: 0.8,
          invalidateOnResize: true,
          onEnter: () => {
            this.onEnterFunc( id );
          },
          onEnterBack: () => {
            this.onEnterFunc( id );
          },
          onLeave: () => {
            this.onLeaveFunc( id );
          },
          onLeaveBack: () => {
            this.onLeaveFunc( id );
          }
        }
      } );
    } );
  }

  init() {
    if ( !this.scroll ) {
      return;
    }
    this.createDOM();
    this.scrollFunc();
  }
}

const scrollEls = document.querySelectorAll( '.bs-section--on-scroll-slider' );
const scrollElObjs = [];
scrollEls.forEach( ( scrollEl ) => {
  const scrollObj = new ScrollSlider( scrollEl );
  scrollElObjs.push( scrollObj );
} );
const loadHandler = () => {
  scrollElObjs.forEach( ( scrollElObj ) => {
    scrollElObj.init();
  } );
};
window.addEventListener( 'load', loadHandler );
