$( document ).ready( function() {
  const videoTabSlider = $( '.bs-section--video-tab-slider' );
  if( videoTabSlider.length ){
    videoTabSlider.find( '.bs-slider-content .media-elements' ).each( function() {
      let imgSrc = $( this ).find( 'img' ).attr( 'src' );
              
      $( this ).closest( '.bs-div--content-wrapper' ).css( 'background-image', `url(${imgSrc})` );
    } );
  }
} );
