export default class SVGAnimation {
  constructor( row ) {
    this.row = row;
    this.svgQueue = [];
    this.svgHoverQueue = [];
    this.isCircular = row.classList.contains( 'bs-row--animation-repeat' );
    this.isHover = row.classList.contains( 'bs-row--animation-hover' );
    this.isClick = row.classList.contains( 'bs-row--animation-click' );
    this.isOnlyHover = row.classList.contains( 'bs-row--animation-only-hover' );
    this.interval = 2000;
    this.activeTimeout = null;
    this.cards = row.querySelectorAll( '.bs-div--svg-animation' );
    this.observer = new IntersectionObserver( ( entries ) => {
      this.observerHandler( entries );
    }, {
      root: null,
      rootMargin: '-15%',
    } );
  }
  restart() {
    this.terminate();
    this.fetchSVGs();
    this.play();
  }
  hasSlides() {
    return this.row.querySelectorAll( '.slick-slide' ).length !== 0;
  }
  fetchSVGs() {
    this.svgQueue = [];
    this.cards.forEach( card => {
      const svgatorObject = card.querySelectorAll( '.wp-block-e25m-svg-animation-block object' );
      const svgatorDocument = svgatorObject[0]?.contentDocument || svgatorObject.contentWindow?.document;
      const svgatorElement = svgatorDocument?.querySelector( 'svg' );
      let hoverSvg = svgatorElement;
      if ( svgatorObject.length > 1 ){
        const svgatorDocumentHover = svgatorObject[1]?.contentDocument || svgatorObject.contentWindow?.document;
        const svgHover = svgatorDocumentHover?.querySelector( 'svg' );
        const svgatorElementHover = svgHover ? svgHover : svgatorElement ;
        hoverSvg = svgatorElementHover;
      }
      this.svgHoverQueue.push( hoverSvg );
      this.svgQueue.push( ( card.classList.contains( 'expanded' ) )? hoverSvg: svgatorElement );
    } );
  }
  play( index = 0 ) {
    if ( this.svgQueue.length === 0 ) return;
    if ( this.svgQueue.length === index && this.hasSlides() === false ) {
      if ( !this.isCircular ) return;
      index = 0;
    }
    if( !this.svgQueue[index] ) return;
    const player = this.svgQueue[index].svgatorPlayer;
    player.on( 'end', () => {
      this.activeTimeout = setTimeout( () => {
        this.play( index + 1 );
      }, this.interval );
      player.off( 'end' );
    } );
    player.play();
  }
  hoverIcon() {
    this.cards.forEach( ( card, index )=> {
      card.addEventListener( 'mouseenter', () => {
        this.terminate();
        setTimeout( () => {
          this.svgHoverQueue[index].svgatorPlayer.play();
        }, 0 );
      } );
    } );
  }
  hoverIconOnly() {
    if( !this.isOnlyHover ) return;
    this.hoverIcon();
  }
  hover() {
    if( this.isHover === false ) return;
    this.hoverIcon();
    this.hoverIconOut();
  }
  hoverIconOut(){
    this.row.addEventListener( 'mouseleave', () => {
      this.terminate();
      this.play();
    } );
  }
  clickIcon() {
    if( this.isClick === false ) return;
    this.cards.forEach( ( card, index )=> {
      card.addEventListener( 'click', () => {
        this.terminate();
        this.svgHoverQueue[index].svgatorPlayer.play();
      } );
    } );
  }
  terminate(){
    if( this.activeTimeout ){
      this.svgQueue.forEach( ( svg ) => svg.svgatorPlayer.stop() );
      clearTimeout( this.activeTimeout );
      this.activeTimeout = null;
    }
  }
  observerHandler( entries ) {
    if ( entries[0].isIntersecting ) {
      if( this.isCircular ){
        this.observer.disconnect();
      }
      if( this.isOnlyHover ) return;
      this.terminate();
      this.play();
    }
  }
  init() {
    this.fetchSVGs();
    this.hover();
    this.clickIcon();
    this.hoverIconOnly();
    this.observer.observe( this.row );
  }
}

const svgAnimatedRows = document.querySelectorAll( '.bs-row--common-svg-animation' );
document.addEventListener( 'readystatechange', () => {
  if( document.readyState === 'complete' && window.innerWidth > 1199	) {
    // this is not working with debounce. so added setTimeout
    setTimeout( () => {
      svgAnimatedRows.forEach( ( row ) => {
        const svgAnimation = new SVGAnimation( row );
        svgAnimation.init();
      } );
    },1000 );
  }
} );
