document.addEventListener( 'DOMContentLoaded', () => {
  let xTitle = '';
  const siteNameTag = document.querySelector( 'meta[property="og:site_name"]' );
  const siteName = siteNameTag.getAttribute( 'content' );
  const ogTitleTag = document.querySelector( 'meta[property="og:title"]' );
  const xTitleTag = document.querySelector( 'meta[name="twitter:title"]' );
  if ( xTitleTag ) {
    xTitle = xTitleTag.getAttribute( 'content' );
  } else {
    xTitle = ogTitleTag.getAttribute( 'content' );
  }
  let xLink = document.querySelector( '.heateor_sss_button_x' );
  if ( xLink ) {
    xLink.href = xLink.href.replace( '%25%25title%25%25', encodeURI( xTitle ) );
    xLink.href = xLink.href.replace( '%25%25sep%25%25', encodeURI( '|' ) );
    xLink.href = xLink.href.replace( '%25%25sitename%25%25', encodeURI( siteName ) );
    xLink.href = xLink.href.replace( '%20%7C%20Veracode%20%7C%20Veracode', '%20%7C%20Veracode' );
  }
} );